.UserConnection-List {
  list-style: none;
  padding: 0px;
  margin: 0px;
  /* border: 1px solid #eee; */
  border-radius: 10px;
  margin-bottom: 10px;
}

.UserConnection-List li {
  /* padding: 15px; */
  border-bottom: 1px solid #eee;
  text-align: left;
  /* cursor: pointer; */
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 5px;
  /* border-radius: 15px; */
}

.UserConnection-List li:last-child {
  border: 0px;
}

.UserConnection-List li a {
  padding-right: 15px;
  padding-left: 15px;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  border-radius: 15px;
  background-color: #eee;
}

.UserConnection-List li:hover {
  background-color: #eee;
  font-weight: bold !important;
}

/* .UserConnection-List .UserConnection-Name:hover {
  font-weight: bold !important;
} */

/* .UserConnection-List  li:last-child {
  border-bottom: 0px;
} */
