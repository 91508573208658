.Action {
  /* border:1px solid #eee; */
  /* border-radius: 10px; */
  /* padding: 15px; */
  /* margin-bottom: 15px; */
  position: relative;
}

.Action-Me {
  border: 1px solid blue;
}

.Action-Content {
  /* display: flex; */
  /* margin-top: 5px; */
  text-align: left;
}

.Action-Icon {
  display: inline-block;
  text-align: left;
  height: 55px;
}

.Action-From {
  text-align: left;
  /* margin-bottom: 15px; */
  font-size: 12px;
}

.Action-From a {
  /* color: #eb8dbb; */
  text-decoration: none;
}

.Action-Text {
  font-family: 'Poppins', sans-serif;
  /* font-style: inherit; */
  display: inline-block;
  /* margin-left: 15px; */
  text-align: left;
  margin: 0px;
  margin-top: 5px;
}

.Action-Icon {
  background-color: #eee;
  border-radius: 50px;
  padding: 5px;
}

.Action pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.Action-GratitudeType {
  /* background-color: #eee; */
  /* border-radius: 50px; */
  /* padding: 5px;
  padding-top:20px;
  padding-left: 10px;
  padding-right: 10px;
  width: 10px; */
  /* text-transform: capitalize; */
  /* margin-left: 5px; */
  /* font-weight: bold; */
  position: absolute;
  bottom: 0;
  right: 0;
}

.Action-IsPrivate {
  position: absolute;
  bottom: -3px;
  right: 20px;
}

.Action-Date {
  /* font-size: 14px; */
  color: #aeaeae;
}

.Action-Text b {
  color: #498bed;
}
