.GiveThanks {
  /* padding: 10px; */
  background-color: '#eee';
  border-radius: 20;
  padding: 5;
  padding-right: 20;
  padding-left: 20;
  margin-bottom: 20;
}

/* .GiveThanks:hover {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
} */

.GiveThanks-Label {
  margin-bottom: 25px;
  background-color: rgb(237, 247, 237);
  padding: 15px;
  text-align: left;
  overflow: scroll;
  line-height: 1.5;
  border-radius: 10px;
  font-size: 14px;
}

.Suggestion-Username {
  font-size: 10px;
}

.GiveThanks-PersonNotified {
  font-size: 12px;
  text-align: right;
  font-style: 'Poppins', sans-serif;
  /* height: 45px; */
}

.GiveThanks-Example {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-left: 5px solid silver;
  font-style: italic;
}

.GiveThanks-Buttons {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  /* text-align: right; */
}

.Mention__input {
  /* border-radius: 10px; */
}

.GiveThanks-Chips .MuiChip-root {
  margin-right: 5px;
  margin-top: 5px;
}

.GiveThanks-Text-Hint {
  text-align: left;
  font-size: 12px;
  margin-bottom: 5px;
}
