.Auth-Intro {
  text-align: left;
  font-size: 34px;
  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  /* background-color: #eee; */
}

.Auth-Smaller {
  font-size: 24px;
}

.Auth-Logo {
  width: 400px;
  margin: auto;
}

.Auth-Screenshot {
  /* margin: 50px; */
  padding: 15px;
  border-radius: 5px;
}

.Auth-Screenshot img {
  width: 100%;
  border: 1px solid black;
}

.Auth-Quote {
  font-size: 20px;
  /* padding: 20px; */
  /* font-size: italic; */
  font-weight: 600;
  margin-top: 20px;
  color: white;
}

.Auth-Text-Sm {
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  color: white;
}

.Auth-Text-Xs {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.Auth-LeftSide {
  background-color: #eb8dbb;
  z-index: 10;
}

.Auth-Quote-Author {
  font-size: 12px;
  color: white;
}

@media screen and (max-width: 600px) {
  .Auth-Logo {
    width: 200px;
  }

  .SignIn {
    margin-top: 0px;
  }
}
