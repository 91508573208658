.UserConnection-Email {
  margin-left: 10px;
  display: inline-block;
}

.UserConnection-Name {
  text-decoration: none;
  color: black;
  font-weight: 400;
}

.UserConnection-Link {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  text-align: left;
}
