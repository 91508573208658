body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
  /* 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
  /* sans-serif; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root,
body,
html,
.App {
  height: 100%;
  min-height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

/* .primary-color {
  color: #eb8dbb;
} */

.primary-link {
  color: #498bed;
  font-size: 16px;
  font-weight: 600;
}

.cap {
  text-transform: capitalize;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
