.UserConnection-Name {
  margin-bottom: 5px;
  padding-bottom: 5px;
  /* text-align: center; */
  /* display: flex; */
  /* justify-content: center; */
  /* flex-direction: column; */
  /* padding-right: 15px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 15px; */

  padding: 10px;
  /* cursor: pointer; */
}

.UserConnection-Name li:hover {
  background-color: #eee;
  /* font-weight: bold !important;   */
}

.ConnectionUser-KarmaLabel {
  margin-bottom: 20px;
}
