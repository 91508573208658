.FriendshipApproval {
}

.FriendshipApproval li {
  /* padding: 10px; */
  text-align: left;
  border-bottom: 1px solid silver;
}

.FriendshipApproval ul {
  /* background-color: rgb(229, 246, 253); */
  border-radius: 4px;
  list-style: none;
  margin: 0px;
  padding: 0px;
  /* border: 1px solid silver; */
}

.FriendshipApproval li:last-child {
  border-bottom: 0px;
}

.FriendshipApproval-Name {
  display: inline-block;
  font-weight: bold;
}

.FriendshipApproval-Name a {
  color: black;
}

.FriendshipApproval-CreatedAt {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
}
